import { gql } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from './member'

export const APP_GET_ACTIVITIES = gql`
  query APP_GET_ACTIVITIES($condition: activity_bool_exp, $limit: Int, $offset: Int, $orderBy: [activity_order_by!]) {
    activity_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
    activity(where: $condition, limit: $limit, offset: $offset, order_by: $orderBy) {
      id
      title
      list_price
      slug
      featured_image_url
      cover_video_url
      abstract
      is_private
      status
      position

      default_location
      default_duration
      participant_limit

      is_price_hidden: metadata(path: "isPriceHidden")
      is_author_info_hidden: metadata(path: "isAuthorInfoHidden")
      is_participant_count_hidden: metadata(path: "isParticipantCountHidden")
      is_discount_countdown_visible: metadata(path: "isDiscountCountdownVisible")

      activity_instructors {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }

      activity_sessions {
        id
        title
        start_at
        end_at
        sell_start_at
        sell_end_at
        list_price
        quantity
      }

      activity_categories {
        id
        category {
          id
          name
          slug
        }
      }

      activity_possessions_aggregate {
        aggregate {
          count
        }
      }
      product_review_summary {
        review_count
        average_rating
      }
      activity_session_member_summaries_aggregate(where: { revoked_at: { _is_null: true } }) {
        aggregate {
          count
        }
      }

      activity_visibility {
        visibility_type
        purchasable_type
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const ADMIN_GET_ACTIVITIES = gql`
  query ADMIN_GET_ACTIVITIES($condition: activity_bool_exp, $limit: Int, $offset: Int, $orderBy: [activity_order_by!]) {
    activity(where: $condition, limit: $limit, offset: $offset, order_by: $orderBy) {
      id
      title
      abstract
      member_id
      list_price
      position
      slug
      featured_image_url
      cover_video_url
      is_private
      is_live
      status

      default_location
      default_duration
      participant_limit
      custom_profit_sharing_ratio
      type: metadata(path: "type")
      session_type: metadata(path: "sessionType")

      activity_instructors {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }
      activity_assistants {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }
      member {
        ...MEMBER_PUBLIC_COLUMNS
      }
      reviewer {
        ...MEMBER_PUBLIC_COLUMNS
      }

      online_at
      offline_at
      start_at
      end_at
      created_at
      updated_at
      published_at
      rejected_at
      reviewing_at
      deleted_at

      activity_sessions {
        id
        start_at
      }

      activity_categories {
        id
        category {
          id
          name
          slug
        }
      }

      activity_visibility {
        visibility_type
        purchasable_type
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const UPDATE_ACTIVITY_SESSION = gql`
  mutation UPDATE_ACTIVITY_SESSION($activitySessionId: uuid!, $set: activity_session_set_input) {
    update_activity_session_by_pk(pk_columns: { id: $activitySessionId }, _set: $set) {
      id
      published_at
    }
  }
`

export const INSERT_ACTIVITY_SESSION_MEMBER = gql`
  mutation INSERT_ACTIVITY_SESSION_MEMBER($object: activity_session_member_insert_input!) {
    insert_activity_session_member_one(object: $object) {
      id
      no
    }
  }
`

export const INSERT_ACTIVITY_SESSION_MEMBERS = gql`
  mutation INSERT_ACTIVITY_SESSION_MEMBERS($objects: [activity_session_member_insert_input!]!) {
    insert_activity_session_member(objects: $objects) {
      returning {
        id
        no
      }
    }
  }
`

export const UPDATE_ACTIVITY_SESSION_MEMBERS = gql`
  mutation UPDATE_ACTIVITY_SESSION_MEMBERS(
    $condition: activity_session_member_bool_exp!
    $set: activity_session_member_set_input!
  ) {
    update_activity_session_member(where: $condition, _set: $set) {
      affected_rows
    }
  }
`

export const UPDATE_ACTIVITY_SESSION_MEMBER_BY_ID = gql`
  mutation UPDATE_ACTIVITY_SESSION_MEMBER_BY_ID(
    $activitySessionMemberId: uuid!
    $set: activity_session_member_set_input!
  ) {
    update_activity_session_member_by_pk(pk_columns: { id: $activitySessionMemberId }, _set: $set) {
      id
    }
  }
`

export const GET_ACTIVITY_SESSION_MEMBER = gql`
  query GET_ACTIVITY_SESSION_MEMBER($condition: activity_session_member_bool_exp) {
    activity_session_member(where: $condition, limit: 1, order_by: { activity_session: { start_at: asc_nulls_last } }) {
      id
    }
  }
`

export const INSERT_ACTIVITY_SESSION_MEMBER_EVENT = gql`
  mutation INSERT_ACTIVITY_SESSION_MEMBER_EVENT($activitySessionMemberId: uuid!, $trackingData: jsonb!) {
    update_activity_session_member_by_pk(
      pk_columns: { id: $activitySessionMemberId }
      _set: { checked_in_at: "now()" }
      _inc: { click_count: 1 }
    ) {
      id
    }
    insert_activity_session_member_event_one(
      object: { activity_session_member_id: $activitySessionMemberId, tracking_data: $trackingData }
    ) {
      id
    }
  }
`
