import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { ProductOrderType, ProductStatus } from '@havppen/types/src/product'
import { notEmpty } from '@havppen/utils/src/array'
import {
  productMemberTagRestrictionFilterTypes,
  productMemberTagRestrictionTypes,
  productVisibilityTypes,
} from 'src/types/productVisibility'
import { NIL as NIL_UUID } from 'uuid'

export type ActivitiesQueryOptions = {
  limit?: number
  offset?: number
  status?: ProductStatus
  orderBy?: ProductOrderType
  search?: string | null
  authorId?: string
  categorySlug?: string | null
  categoryId?: string | null
  showExpired?: boolean
  showPrivate?: boolean
  purchaseMemberId?: string
  pollInterval?: number
  isAuthenticated?: boolean
  currentMemberId?: string
}
export const mapActivitiesQueryOptionsToGqlVariables = (options: ActivitiesQueryOptions, params: { appId: string }) => {
  const orderType = options.orderBy || 'incoming'
  const orderBy: types.activity_order_by[] | undefined =
    orderType === 'incoming'
      ? [{ activity_sessions_aggregate: { min: { start_at: 'desc_nulls_last' as types.order_by } } }]
      : orderType === 'hot'
      ? [{ activity_sessions_aggregate: { count: 'desc' as types.order_by } }, { online_at: 'desc' as types.order_by }]
      : orderType === 'recommend'
      ? [{ weight: 'desc' as types.order_by }, { online_at: 'desc' as types.order_by }]
      : orderType === 'latest'
      ? [{ online_at: 'desc' as types.order_by }]
      : [{ position: 'asc' as types.order_by }]

  const activityVisibilityQueries: types.InputMaybe<types.activity_bool_exp[]> = [
    {
      _not: {
        activity_visibility: {},
      },
    },
    {
      _not: {
        activity_visibility: {
          visibility_type: { _in: [productVisibilityTypes.authOnly, productVisibilityTypes.whitelist] },
        },
      },
    },
  ]
  if (options?.isAuthenticated) {
    activityVisibilityQueries.push({
      activity_visibility: {
        visibility_type: { _eq: productVisibilityTypes.authOnly },
      },
    })
  }
  if (options?.currentMemberId && options?.currentMemberId !== NIL_UUID) {
    activityVisibilityQueries.push({
      activity_visibility: {
        visibility_type: { _eq: productVisibilityTypes.whitelist },
        product_member_tag_restrictions: {
          type: { _eq: productMemberTagRestrictionTypes.visibility },
          filter_type: { _eq: productMemberTagRestrictionFilterTypes.include },
          member_tag_members: { member_id: { _eq: options.currentMemberId } },
        },
      },
    })
  }

  const variables: types.APP_GET_ACTIVITIESVariables = {
    limit: options?.limit,
    offset: options?.offset,
    orderBy,
    condition: {
      app_id: { _eq: params.appId },
      status: options?.status ? { _eq: options.status } : undefined,
      is_private: options.showPrivate ? undefined : { _eq: false },
      activity_instructors: options?.authorId ? { member_id: { _eq: options.authorId } } : undefined,
      activity_categories: options?.categorySlug
        ? { category: { slug: { _eq: options.categorySlug } } }
        : options?.categoryId
        ? { category: { id: { _eq: options.categoryId } } }
        : undefined,
      activity_sessions: options.showExpired ? undefined : { is_expired: { _eq: false } },
      activity_possessions: options.purchaseMemberId ? { member_id: { _eq: options.purchaseMemberId } } : undefined,
      deleted_at: { _is_null: true },
      online_at: { _lte: 'now()' },
      _and: [
        { _or: [{ offline_at: { _is_null: true } }, { offline_at: { _gte: 'now()' } }] },
        ...(options?.search
          ? [
              {
                _or: [
                  { title: { _ilike: `%${options.search}%` } },
                  { activity_instructors: { member: { name: { _ilike: `%${options.search}%` } } } },
                  { activity_instructors: { member: { display_name: { _ilike: `%${options.search}%` } } } },
                ],
              },
            ]
          : []),
      ],
      _or: activityVisibilityQueries,
    },
  }
  return variables
}

export const formatGqlActivities = (data: types.APP_GET_ACTIVITIES | undefined, defaultAvatarUrl?: string | null) => {
  const activities = (data?.activity || []).map(activity => {
    const categories = activity.activity_categories.map(activity_category => activity_category.category)
    const activitySlug = activity.slug ?? activity.id
    const path = `/activity/${activitySlug}`

    const sessions = activity.activity_sessions.map(activity_session => ({
      id: activity_session.id,
      title: activity_session.title,
      startAt: new Date(activity_session.start_at),
      endAt: new Date(activity_session.end_at),
      sellStartAt: activity_session.sell_start_at ? new Date(activity_session.sell_start_at) : null,
      sellEndAt: activity_session.sell_end_at ? new Date(activity_session.sell_end_at) : null,
      listPrice: activity_session.list_price,
      quantity: activity_session.quantity,
    }))
    sessions.sort((a, b) => a.startAt.getTime() - b.startAt.getTime())

    return {
      id: activity.id,
      title: activity.title,
      listPrice: activity.list_price,
      slug: activity.slug,
      path,
      featuredImageUrl: activity.featured_image_url,
      coverVideoUrl: activity.cover_video_url,
      isPrivate: activity.is_private ?? false,
      status: activity.status as ProductStatus,
      defaultLocation: activity.default_location,
      defaultDuration: activity.default_duration,
      participantLimit: activity.participant_limit,

      reviewCount: activity.product_review_summary?.review_count ?? null,
      averageRating: activity.product_review_summary?.average_rating ?? null,

      isPriceHidden: activity.is_price_hidden ?? false,
      isAuthorInfoHidden: activity.is_author_info_hidden ?? false,
      isParticipantCountHidden: activity.is_participant_count_hidden ?? false,
      isDiscountCountdownVisible: activity.is_discount_countdown_visible ?? false,

      instructorIds: activity.activity_instructors.map(activity_instructor => activity_instructor.member_id),
      instructors: activity.activity_instructors
        .map(activity_instructor => activity_instructor.member)
        .map(member => mapMemberPublicFromGql(member, { defaultAvatarUrl }))
        .filter(notEmpty),
      sessions,
      categories,
      purchaseCount: activity.activity_possessions_aggregate.aggregate?.count || 0,
      participantCount: activity.activity_session_member_summaries_aggregate.aggregate?.count || 0,
    }
  })
  const now = new Date()
  const upcomingActivities = [...activities].filter(activity => activity.sessions[0].startAt > now)
  const expiredActivities = [...activities].filter(activity => activity.sessions[0].startAt < now)
  upcomingActivities.sort((a, b) => (a.sessions[0]?.startAt.getTime() ?? 0) - (b.sessions[0]?.startAt.getTime() ?? 0))
  expiredActivities.sort((a, b) => (b.sessions[0]?.startAt.getTime() ?? 0) - (a.sessions[0]?.startAt.getTime() ?? 0))

  return [...upcomingActivities, ...expiredActivities]
}
