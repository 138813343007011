'use client'

import { gql, useQuery } from '@apollo/client'
import { APP_GET_ACTIVITIES, GET_ACTIVITY_SESSION_MEMBER } from '@havppen/gql/src/activity'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'
import types from '@havppen/gql/types'
import { ActivityMetadataProps, ActivitySessionType, ActivityType } from '@havppen/types/src/activity'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { ProductDefaultProps, ProductStatus, ProductStyleMetadataBriefProps } from '@havppen/types/src/product'
import { notEmpty } from '@havppen/utils/src/array'
import { useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { MemberPublicBriefProps } from 'src/types/member'
import { NIL as NIL_UUID, validate as isUUID } from 'uuid'
import { ActivitiesQueryOptions, formatGqlActivities, mapActivitiesQueryOptionsToGqlVariables } from './activity'

export type ActivityBriefProps = ProductDefaultProps &
  ProductStyleMetadataBriefProps & {
    title: string
    featuredImageUrl: string | null
    coverVideoUrl: string | null
    isPrivate: boolean
    status: ProductStatus

    defaultLocation: string | null
    defaultDuration: number
    participantLimit: number | null

    instructorIds: string[]
    instructors: MemberPublicBriefProps[]

    sessions: ActivitySessionBriefProps[]
    participantCount: number
  }
export type ActivityProps = Omit<ActivityBriefProps, 'sessions'> & {
  type: ActivityType | null
  sessionType: ActivitySessionType
  assistantIds: string[]
  assistants: MemberPublicBriefProps[]
  abstract: string | null
  content: string | null
  metadata: ActivityMetadataProps | null
  seoAttributes?: {
    [key: string]: any
  }
  maxPurchaseCountPerSession: number | null
}
export type ActivityPurchaseProps = {
  id: string
  slug: string | null
  title: string
  path: string
  featuredImageUrl: string | null

  instructorIds: string[]
  instructors: MemberPublicBriefProps[]
  assistantIds: string[]
  assistants: MemberPublicBriefProps[]

  type: ActivityType | null
  sessionType: ActivitySessionType
  minutesBeforeHeadStart: number
  sessions: ActivitySessionPurchasedProps[]
}

export type ActivitySessionBriefProps = {
  id: string
  title: string | null
  startAt: Date
  endAt: Date
  sellStartAt: Date | null
  sellEndAt: Date | null
  listPrice: number | null
  quantity: number | null
}
export type ActivityUpcomingSessionProps = {
  id: string
  startAt: Date
  endAt: Date
  externalMeetingUrl: string | null
}
export type ActivitySessionProps = ActivitySessionBriefProps & {
  location: string | null
  minPurchaseLimit: number
  maxPurchaseLimit: number | null
  sessionGroup: ActivitySessionGroupProps | null
  currentReservedCount: number
  externalMeetingUrl: string | null
}
export type ActivitySessionDetailProps = ActivitySessionProps & {
  activityId: string
  activityTitle: string
  instructorIds: string[]
  instructors: MemberPublicBriefProps[]
  assistantIds: string[]
  assistants: MemberPublicBriefProps[]
  members: ActivitySessionMemberProps[]
}
export type ActivitySessionPurchasedProps = {
  id: string
  activityTitle: string
  title: string | null
  startAt: Date
  endAt: Date
  location: string | null
  externalMeetingUrl: string | null
  sessionGroup: ActivitySessionGroupProps | null
  members: ActivitySessionMemberProps[]
}
export type ActivitySessionMemberProps = {
  id: string
  no: number
  sn: number | null
  receiverId: string | null
  receivedAt: Date | null
  receiver: MemberPublicBriefProps | null
  revokedAt: Date | null
  comment: string | null
}

export type ActivitySessionGroupProps = {
  id: string
  title: string | null
  position: number
}

export type ActivitySessionReserveStatus = 'LOCKED' | 'RESERVED'
export type ActivitySessionStatusProps = {
  activitySessionId: string
  reserveCount: number
  reserveStatus: ActivitySessionReserveStatus
}

export const getActivitySessionExternalMeetingUrl = (option: {
  type: ActivityType | undefined
  activityId?: string
  activitySessionId: string
  activitySessionMemberId?: string
  defaultExternalMeetingUrl?: string
  isProductEditor?: boolean
}) => {
  const { activityId, activitySessionId, activitySessionMemberId, defaultExternalMeetingUrl, isProductEditor } =
    option || {}

  if (activityId) {
    return `/activity/join/${activityId}`
  } else if (activitySessionId) {
    return `/activity/join-session/${activitySessionId}`
  } else if (isProductEditor) {
    return defaultExternalMeetingUrl || `https://meet.jit.si/${activitySessionId}`
  } else {
    return null
  }
}

export const useActivities = (options?: ActivitiesQueryOptions) => {
  const { id: appId, defaultAvatarUrl } = useApp()
  const { isAuthenticated, currentMemberId } = useAuth()

  const { loading, error, data, refetch } = useQuery<types.APP_GET_ACTIVITIES, types.APP_GET_ACTIVITIESVariables>(
    APP_GET_ACTIVITIES,
    {
      variables: mapActivitiesQueryOptionsToGqlVariables(
        {
          isAuthenticated,
          currentMemberId,
          ...options,
        },
        { appId },
      ),
      context: { important: true },
      pollInterval: options?.pollInterval,
    },
  )

  const activities: ActivityBriefProps[] = useMemo(
    () => formatGqlActivities(data, defaultAvatarUrl),
    [data, defaultAvatarUrl],
  )

  return {
    activitiesCount: data?.activity_aggregate.aggregate?.count || 0,
    loadingActivities: loading,
    errorActivities: error,
    activities,
    refetchActivities: refetch,
  }
}

export const useActivitiesPurchased = (options?: ActivitiesQueryOptions) => {
  const { currentMemberId } = useAuth()
  const { id: appId, defaultAvatarUrl } = useApp()
  const condition: types.APP_GET_ACTIVITIES_PURCHASEDVariables['condition'] = useMemo(
    () => ({
      app_id: { _eq: appId },
      status: options?.status ? { _eq: options.status } : undefined,
      is_private: options?.showPrivate ? undefined : { _eq: false },
      activity_instructors: options?.authorId ? { member_id: { _eq: options.authorId } } : undefined,
      activity_categories: options?.categorySlug ? { category: { slug: { _eq: options.categorySlug } } } : undefined,
      activity_possessions: options?.purchaseMemberId ? { member_id: { _eq: options.purchaseMemberId } } : undefined,
      deleted_at: { _is_null: true },
      online_at: { _lte: 'now()' },
      _and: [
        { _or: [{ offline_at: { _is_null: true } }, { offline_at: { _gte: 'now()' } }] },
        ...(options?.search
          ? [
              {
                _or: [
                  { title: { _ilike: `%${options.search}%` } },
                  { activity_instructors: { member: { name: { _ilike: `%${options.search}%` } } } },
                  { activity_instructors: { member: { display_name: { _ilike: `%${options.search}%` } } } },
                ],
              },
            ]
          : []),
      ],
    }),
    [options, appId],
  )

  const orderBy: types.activity_order_by[] | undefined = useMemo(
    () =>
      options?.orderBy === 'incoming'
        ? [{ activity_sessions_aggregate: { max: { start_at: 'desc_nulls_last' as types.order_by } } }]
        : options?.orderBy === 'hot'
        ? [
            { activity_sessions_aggregate: { count: 'desc' as types.order_by } },
            { online_at: 'desc' as types.order_by },
          ]
        : options?.orderBy === 'recommend'
        ? [{ weight: 'desc' as types.order_by }, { online_at: 'desc' as types.order_by }]
        : options?.orderBy === 'latest'
        ? [{ online_at: 'desc' as types.order_by }]
        : undefined,
    [options?.orderBy],
  )

  const { loading, error, data, refetch } = useQuery<
    types.APP_GET_ACTIVITIES_PURCHASED,
    types.APP_GET_ACTIVITIES_PURCHASEDVariables
  >(APP_GET_ACTIVITIES_PURCHASED, {
    variables: {
      currentMemberId,
      limit: options?.limit,
      offset: options?.offset,
      orderBy,
      condition,
    },
    context: { important: true },
    pollInterval: options?.pollInterval,
  })

  const activities: ActivityPurchaseProps[] = useMemo(
    () =>
      (data?.activity || []).map(activity => {
        const activitySlug = activity.slug ?? activity.id
        const path = `/activity/${activitySlug}`

        const activityType = activity.type
        const isOnlineActivity = activityType !== 'offline'

        const instructorIds = activity.activity_instructors.map(activity_instructor => activity_instructor.member_id)
        const instructors = activity.activity_instructors
          .map(activity_instructor => activity_instructor.member)
          .map(member => mapMemberPublicFromGql(member, { defaultAvatarUrl }))
          .filter(notEmpty)

        const assistantIds = activity.activity_assistants.map(activity_assistant => activity_assistant.member_id)
        const assistants = activity.activity_assistants
          .map(activity_assistant => activity_assistant.member)
          .map(member => mapMemberPublicFromGql(member, { defaultAvatarUrl }))
          .filter(notEmpty)

        return {
          id: activity.id,
          title: activity.title,
          slug: activity.slug,
          path,
          featuredImageUrl: activity.featured_image_url,

          type: activityType,
          sessionType: activity.session_type ?? 'single',
          minutesBeforeHeadStart: activity.minutes_before_head_start ?? 20,

          instructorIds,
          instructors,

          assistantIds,
          assistants,

          sessions: activity.activity_sessions.map(activity_session => ({
            id: activity_session.id,
            title: activity_session.title,
            location: activity_session.location,
            activityId: activity.id,
            activityTitle: activity.title,

            startAt: new Date(activity_session.start_at),
            endAt: new Date(activity_session.end_at),

            instructorIds,
            instructors,
            assistantIds,
            assistants,

            sessionGroup: activity_session.activity_session_group
              ? {
                  id: activity_session.activity_session_group.id,
                  title: activity_session.activity_session_group.title,
                  position: activity_session.activity_session_group.position ?? 0,
                }
              : null,
            externalMeetingUrl: isOnlineActivity
              ? getActivitySessionExternalMeetingUrl({
                  type: activityType,
                  activityId: activity.id,
                  activitySessionId: activity_session.id,
                  activitySessionMemberId: activity_session.activity_session_members[0]?.id,
                })
              : null,
            members: activity_session.activity_session_members.map(activity_session_member => ({
              id: activity_session_member.id,
              no: activity_session_member.no,
              sn: activity_session_member.sn,
              receiverId: activity_session_member.receive_member_id,
              receivedAt: activity_session_member.received_at ? new Date(activity_session_member.received_at) : null,
              receiver: mapMemberPublicFromGql(activity_session_member.receiver),
              revokedAt: activity_session_member.revoked_at ? new Date(activity_session_member.revoked_at) : null,
              comment: activity_session_member.comment,
            })),
          })),
        }
      }),
    [data, defaultAvatarUrl],
  )

  return {
    activitiesCount: data?.activity_aggregate.aggregate?.count || 0,
    loadingActivities: loading,
    errorActivities: error,
    activities,
    refetchActivities: refetch,
  }
}

export const useActivity = (activityIdOrSlug: string) => {
  const { defaultAvatarUrl } = useApp()
  const isActivityId = useMemo(() => isUUID(activityIdOrSlug), [activityIdOrSlug])

  const { loading, error, data, refetch } = useQuery<types.APP_GET_ACTIVITY, types.APP_GET_ACTIVITYVariables>(
    APP_GET_ACTIVITY,
    {
      variables: {
        condition: {
          id: isActivityId ? { _eq: activityIdOrSlug } : undefined,
          slug: isActivityId ? undefined : { _eq: activityIdOrSlug },
        },
      },
    },
  )

  const activity: ActivityProps | null = useMemo(() => {
    return loading || error || !data || data.activity.length === 0
      ? null
      : data.activity.map(activity => {
          const categories = activity.activity_categories.map(activity_category => activity_category.category)
          const activitySlug = activity.slug ?? activity.id
          const path = `/activity/${activitySlug}`

          return {
            id: activity.id,
            title: activity.title,
            listPrice: activity.list_price,
            slug: activity.slug,
            path,
            featuredImageUrl: activity.featured_image_url,
            coverVideoUrl: activity.cover_video_url,
            isPrivate: activity.is_private ?? false,
            status: activity.status as ProductStatus,
            type: activity.type,
            sessionType: activity.session_type ?? 'single',
            abstract: activity.abstract,
            content: activity.content,
            metadata: activity.metadata,
            seoAttributes: activity.seo_attributes,
            defaultLocation: activity.default_location,
            defaultDuration: activity.default_duration,
            participantLimit: activity.participant_limit,
            maxPurchaseCountPerSession: activity.max_purchase_count_per_session,

            isPriceHidden: activity.metadata?.isPriceHidden ?? false,
            isAuthorInfoHidden: activity.metadata?.isAuthorInfoHidden ?? false,
            isParticipantCountHidden: activity.metadata?.isParticipantCountHidden ?? false,
            isDiscountCountdownVisible: activity.metadata?.isDiscountCountdownVisible ?? false,

            instructorIds: activity.activity_instructors.map(activity_instructor => activity_instructor.member_id),
            assistantIds: activity.activity_assistants.map(activity_assistant => activity_assistant.member_id),
            instructors: activity.activity_instructors
              .map(activity_instructor => activity_instructor.member)
              .map(member => mapMemberPublicFromGql(member, { defaultAvatarUrl }))
              .filter(notEmpty),
            assistants: activity.activity_assistants
              .map(activity_assistant => activity_assistant.member)
              .map(member => mapMemberPublicFromGql(member, { defaultAvatarUrl }))
              .filter(notEmpty),

            categories,
            purchaseCount: activity.activity_possessions_aggregate.aggregate?.count || 0,
            participantCount: activity.activity_session_member_summaries_aggregate.aggregate?.count || 0,

            reviewCount: activity.product_review_summary?.review_count ?? null,
            averageRating: activity.product_review_summary?.average_rating ?? null,
          }
        })[0]
  }, [data, defaultAvatarUrl, error, loading])

  return {
    loadingActivity: loading,
    errorActivity: error,
    activity,
    refetchActivity: refetch,
  }
}

export const useActivitySessions = (activityIdOrSlug: string) => {
  const isActivityId = useMemo(() => isUUID(activityIdOrSlug), [activityIdOrSlug])
  const { loading, error, data, refetch } = useQuery<
    types.APP_GET_ACTIVITY_SESSIONS,
    types.APP_GET_ACTIVITY_SESSIONSVariables
  >(APP_GET_ACTIVITY_SESSIONS, {
    variables: {
      condition: {
        activity_id: isActivityId ? { _eq: activityIdOrSlug } : undefined,
        activity: isActivityId ? undefined : { slug: { _eq: activityIdOrSlug } },
        start_at: { _is_null: false },
      },
    },
  })

  const activitySessions: ActivitySessionProps[] = useMemo(() => {
    const activitySessions = (data?.activity_session || []).map(activity_session => ({
      id: activity_session.id,
      title: activity_session.title,
      location: activity_session.location,
      startAt: new Date(activity_session.start_at),
      endAt: new Date(activity_session.end_at),
      sellStartAt: activity_session.sell_start_at ? new Date(activity_session.sell_start_at) : null,
      sellEndAt: activity_session.sell_end_at ? new Date(activity_session.sell_end_at) : null,
      listPrice: activity_session.list_price,
      quantity: activity_session.quantity,
      minPurchaseLimit: activity_session.min_purchase_limit ?? 1,
      maxPurchaseLimit: activity_session.max_purchase_limit,
      currentReservedCount: activity_session.activity_session_members_aggregate.aggregate?.count || 0,
      externalMeetingUrl: null,
      sessionGroup: activity_session.activity_session_group
        ? {
            id: activity_session.activity_session_group.id,
            title: activity_session.activity_session_group.title,
            position: activity_session.activity_session_group.position ?? 0,
          }
        : null,
    }))

    activitySessions.sort((a, b) => a.startAt.getTime() - b.startAt.getTime())
    return activitySessions
  }, [data])

  return {
    loadingActivitySessions: loading,
    errorActivitySessions: error,
    activitySessions,
    refetchActivitySessions: refetch,
  }
}

export const useUpcomingActivitySession = (activityIdOrSlug: string, isProductEditor?: boolean) => {
  const { currentMemberId } = useAuth()
  const isActivityId = useMemo(() => isUUID(activityIdOrSlug), [activityIdOrSlug])

  const { loading, error, data, refetch } = useQuery<
    types.GET_ACTIVITY_UPCOMING_SESSION,
    types.GET_ACTIVITY_UPCOMING_SESSIONVariables
  >(
    gql`
      query GET_ACTIVITY_UPCOMING_SESSION(
        $activitySessionCondition: activity_session_bool_exp
        $activityCondition: activity_bool_exp
        $memberId: String!
      ) {
        activity_session(where: $activitySessionCondition, limit: 1, order_by: { start_at: asc_nulls_last }) {
          id
          start_at
          end_at

          activity_session_members(where: { member_id: { _eq: $memberId } }, limit: 1) {
            id
          }
        }

        activity(where: $activityCondition, limit: 1) {
          id
          type: metadata(path: "type")
          external_meeting_url: metadata(path: "externalMeetingUrl")
        }
      }
    `,
    {
      skip: currentMemberId === NIL_UUID,
      variables: {
        memberId: currentMemberId,
        activityCondition: {
          id: isActivityId ? { _eq: activityIdOrSlug } : undefined,
          slug: isActivityId ? undefined : { _eq: activityIdOrSlug },
        },
        activitySessionCondition: {
          end_at: { _gte: 'now()' },
          activity_session_members: isProductEditor ? undefined : { member_id: { _eq: currentMemberId } },
          activity: {
            id: isActivityId ? { _eq: activityIdOrSlug } : undefined,
            slug: isActivityId ? undefined : { _eq: activityIdOrSlug },
          },
        },
      },
    },
  )

  const upcomingActivitySession: ActivityUpcomingSessionProps | null = useMemo(() => {
    const activityType = data?.activity[0]?.type
    const isOnlineActivity = activityType !== 'offline'
    const defaultExternalMeetingUrl = data?.activity[0]?.external_meeting_url

    return loading || error || !data
      ? null
      : data.activity_session.map(activity_session => ({
          id: activity_session.id,
          startAt: new Date(activity_session.start_at),
          endAt: new Date(activity_session.end_at),
          externalMeetingUrl: isOnlineActivity
            ? getActivitySessionExternalMeetingUrl({
                type: activityType,
                activityId: data.activity[0].id,
                activitySessionId: activity_session.id,
                activitySessionMemberId: activity_session.activity_session_members[0]?.id,
                defaultExternalMeetingUrl,
                isProductEditor,
              })
            : null,
        }))[0]
  }, [data, error, isProductEditor, loading])

  return {
    loadingUpcomingActivitySession: loading,
    errorUpcomingActivitySession: error,
    upcomingActivitySession,
    refetchUpcomingActivitySession: refetch,
  }
}

export const useActivityJoinPath = ({
  isOfflineActivity,
  isSingleSession,
  activityId,
  activitySessionId,
}: {
  isOfflineActivity: boolean
  isSingleSession: boolean
  activityId?: string
  activitySessionId?: string
}) => {
  const joinPath = useMemo(() => {
    if (activityId) {
      if (!isOfflineActivity && isSingleSession) {
        return `/activity/join/${activityId}`
      } else {
        return `/courses/mine/activity?activityId=${activityId}`
      }
    } else if (activitySessionId) {
      return `/activity/join-session/${activitySessionId}`
    } else {
      return null
    }
  }, [isSingleSession, isOfflineActivity, activityId, activitySessionId])

  return joinPath
}

export const useCurrentMemberActivitySessionsPossessionCount = (activityIdOrSlug: string) => {
  const isActivityId = useMemo(() => isUUID(activityIdOrSlug), [activityIdOrSlug])
  const { currentMemberId } = useAuth()

  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_ACTIVITY_SESSION_POSSESSION,
    types.GET_MEMBER_ACTIVITY_SESSION_POSSESSIONVariables
  >(
    gql`
      query GET_MEMBER_ACTIVITY_SESSION_POSSESSION($condition: activity_session_bool_exp, $memberId: String!) {
        activity_session(where: $condition) {
          id
          activity_session_possessions_aggregate(where: { member_id: { _eq: $memberId } }) {
            aggregate {
              count
            }
          }
        }
      }
    `,
    {
      skip: currentMemberId === NIL_UUID,
      variables: {
        memberId: currentMemberId,
        condition: {
          activity_id: isActivityId ? { _eq: activityIdOrSlug } : undefined,
          activity: isActivityId ? undefined : { slug: { _eq: activityIdOrSlug } },
        },
      },
    },
  )

  const memberActivitySessionsPossessionCount: Record<string, number> = useMemo(
    () =>
      !data
        ? {}
        : data.activity_session.reduce((acc, activity_session) => {
            acc[activity_session.id] = activity_session.activity_session_possessions_aggregate.aggregate?.count ?? 0
            return acc
          }, {} as Record<string, number>),
    [data],
  )
  return {
    loadingMemberActivitySessionsPossessionCount: loading,
    errorMemberActivitySessionsPossessionCount: error,
    memberActivitySessionsPossessionCount,
    refetchMemberActivitySessionsPossessionCount: refetch,
  }
}

export const useActiveActivitySessionMember = (activityIdOrSlug: string) => {
  const isActivityId = useMemo(() => isUUID(activityIdOrSlug), [activityIdOrSlug])

  const { loading, error, data, refetch } = useQuery<
    types.GET_ACTIVITY_SESSION_MEMBER,
    types.GET_ACTIVITY_SESSION_MEMBERVariables
  >(GET_ACTIVITY_SESSION_MEMBER, {
    variables: {
      condition: {
        activity_session: {
          activity_id: isActivityId ? { _eq: activityIdOrSlug } : undefined,
          activity: isActivityId ? undefined : { slug: { _eq: activityIdOrSlug } },
          end_at: { _gte: 'now()' },
        },
      },
    },
  })

  const activeActivitySessionMemberId: string | null = useMemo(() => {
    if (loading || error || !data) {
      return null
    }

    return data.activity_session_member[0]?.id ?? null
  }, [data, error, loading])

  return {
    loadingActiveActivitySessionMember: loading,
    errorActiveActivitySessionMember: error,
    activeActivitySessionMemberId,
    refetchActiveActivitySessionMember: refetch,
  }
}

export const useActivitySessionStatues = (activityId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.APP_GET_ACTIVITY_SESSION_STATUSES,
    types.APP_GET_ACTIVITY_SESSION_STATUSESVariables
  >(
    gql`
      query APP_GET_ACTIVITY_SESSION_STATUSES($condition: activity_session_status_bool_exp) {
        activity_session_status(where: $condition) {
          activity_session_id
          reserve_status
          reserve_count
        }
      }
    `,
    {
      skip: !activityId,
      variables: {
        condition: {
          reserve_status: { _neq: 'REVOKED' },
          activity_session: {
            activity_id: { _eq: activityId },
          },
        },
      },
    },
  )

  const activitySessionStatuses: ActivitySessionStatusProps[] = useMemo(
    () =>
      loading || error || !data
        ? []
        : data.activity_session_status.map(activity_session_status => ({
            activitySessionId: activity_session_status.activity_session_id,
            reserveStatus: activity_session_status.reserve_status as ActivitySessionReserveStatus,
            reserveCount: activity_session_status.reserve_count,
          })),
    [data, error, loading],
  )

  return {
    loadingActivitySessionStatuses: loading,
    errorActivitySessionStatuses: error,
    activitySessionStatuses,
    refetchActivitySessionStatuses: refetch,
  }
}

export const APP_GET_ACTIVITY = gql`
  query APP_GET_ACTIVITY($condition: activity_bool_exp) {
    activity(where: $condition, limit: 1) {
      id
      title
      list_price
      slug
      featured_image_url
      cover_video_url
      is_private
      status

      type: metadata(path: "type")
      session_type: metadata(path: "sessionType")
      max_purchase_count_per_session: metadata(path: "maxPurchaseCountPerSession")

      abstract
      content
      metadata
      seo_attributes

      default_location
      default_duration
      participant_limit

      online_at
      offline_at

      activity_instructors {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }
      activity_assistants {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }

      activity_categories {
        id
        category {
          id
          name
          slug
        }
      }

      activity_possessions_aggregate {
        aggregate {
          count
        }
      }
      activity_session_member_summaries_aggregate(where: { revoked_at: { _is_null: true } }) {
        aggregate {
          count
        }
      }

      product_review_summary {
        review_count
        average_rating
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const GET_ACTIVITY_IDS = gql`
  query GET_ACTIVITY_IDS {
    app {
      app_domains(where: { default: { _eq: true }, type: { _eq: "app" } }) {
        domain
      }
      activities(where: { status: { _eq: "published" } }, limit: 6) {
        id
        slug
        app_id
      }
    }
  }
`

export const APP_GET_ACTIVITY_SESSIONS = gql`
  query APP_GET_ACTIVITY_SESSIONS($condition: activity_session_bool_exp) {
    activity_session(where: $condition, order_by: { start_at: asc_nulls_last }) {
      id
      title
      location
      start_at
      end_at
      sell_start_at
      sell_end_at
      list_price
      quantity
      min_purchase_limit
      max_purchase_limit

      activity_session_group {
        id
        title
        position
      }

      activity {
        id
        title
      }

      activity_session_members_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const APP_GET_ACTIVITIES_PURCHASED = gql`
  query APP_GET_ACTIVITIES_PURCHASED(
    $condition: activity_bool_exp!
    $limit: Int
    $offset: Int
    $orderBy: [activity_order_by!]
    $currentMemberId: String!
  ) {
    activity_aggregate(
      where: {
        _and: [
          {
            _or: [
              { activity_sessions: { activity_session_members: { member_id: { _eq: $currentMemberId } } } }
              { activity_sessions: { activity_session_members: { receive_member_id: { _eq: $currentMemberId } } } }
              { product_editors: { member_id: { _eq: $currentMemberId } } }
            ]
          }
          $condition
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    activity(
      where: {
        _and: [
          {
            _or: [
              { activity_sessions: { activity_session_members: { member_id: { _eq: $currentMemberId } } } }
              { activity_sessions: { activity_session_members: { receive_member_id: { _eq: $currentMemberId } } } }
              { product_editors: { member_id: { _eq: $currentMemberId } } }
            ]
          }
          $condition
        ]
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      slug
      title
      featured_image_url
      type: metadata(path: "type")
      session_type: metadata(path: "sessionType")
      minutes_before_head_start: metadata(path: "minutesBeforeHeadStart")
      activity_instructors {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }
      activity_assistants {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }
      activity_sessions(
        where: {
          _or: [
            { activity_session_members: { member_id: { _eq: $currentMemberId } } }
            { activity_session_members: { receive_member_id: { _eq: $currentMemberId } } }
            { activity: { product_editors: { member_id: { _eq: $currentMemberId } } } }
          ]
        }
        order_by: { start_at: desc_nulls_last }
      ) {
        id
        title
        start_at
        end_at
        location

        activity_session_members(
          where: { _or: [{ member_id: { _eq: $currentMemberId } }, { receive_member_id: { _eq: $currentMemberId } }] }
          order_by: { created_at: desc }
        ) {
          id
          no
          sn
          revoked_at
          comment

          receive_member_id
          received_at
          receiver {
            ...MEMBER_PUBLIC_COLUMNS
          }
        }
        activity_session_group {
          id
          title
          position
        }
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`
